import React from 'react'
import './cases.scss'
import Case from '../../../components/case/Case'


const Cases = () => {
 return(
  <section className='cases' id='cases'>
   <h2>Наши кейсы</h2>
   <Case title='Расписка онлайн'
    task='Протестировать бизнес-идею проекта по созданию и подписи договоров онлайн.' 
    solution={['Нашли способы отстройки от конкурентов (аналитика ниши и конкурентов);',
    'Вытащили боли и потребности пользователей из интервью; ',
    'На основе данных из интервью сделали лендинг;',
    'Запустили рекламные кампании.']}
    result='Подтвердили спрос к проекту и получили окупаемость рекламных вложений 320%.'
    img={'./img/raspiska.webp'}/>
    
   <Case title='Автошкола в Тамбове'
    task='Привлечь больше клиентов в «плохой сезон» не увеличивая рекламный бюджет' 
    solution={[
      'Заполнили раздел товаров и услуг в Яндекс Бизнес;',
      'Разработали систему акций и создали объявления;',
      'Разместили товары на витрине и настроили целевые действия;.'
    ]}
    result='В 3 раза больше обращений и в 2 раза больше заключённых договоров, чем в аналогичный период прошлого года.'
    img={'./img/autoschool.webp'}/>
    
   <Case title='Ликеро-водочный завод (СЛВЗ)'
    task='Сделать многостраничный сайт в фирменной стилистике компании и визуально отстроиться от конкурентов.' 
    solution={[
      'Провели конкурентный анализ и нашли способы как можно выделиться в нише;',
      'Составили карту сайта и определили функционал;',
      'Разработали стилистику на основе фирменного стиля;',
      'Сверстали сайт, подключили домен и оптимизировали для поисковиков.'
    ]}
    result='Вовремя реализовали сайт с учётом всех требований заказчика.'
    img={'./img/slvz.webp'}/>
  </section>
 )
}

export default Cases